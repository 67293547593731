@import '../../styles/tools';

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 space(2);
  height: var(--header-height);

  @include desktop {
    padding: 0 space(6);
  }

  h1 {
    position: relative;
    z-index: 1;
  }

  .buttons {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;

    button {
      background: none;
      border: none;
      font-size: 2.2rem;
      padding: 0;

      @include desktop {
        padding: 50px 0 50px 50px;
        font-size: 3rem;
      }

      .mobile {
        display: inline;

        svg {
          height: 30px;
        }

        @include desktop {
          display: none;
        }
      }

      .desktop {
        display: none;

        @include desktop {
          display: inline;
        }
      }

      &.close {
        position: relative;
        font-size: 0;
        width: 30px;
        height: 30px;
        transform: rotate(45deg);
        padding: 0;

        &::before,
        &::after {
          content: '';
          position: absolute;
          background-color: black;
        }

        &::before {
          width: 100%;
          height: 2px;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
        }

        &::after {
          top: 0;
          left: 50%;
          width: 2px;
          height: 100%;
          transform: translateX(-50%);
        }
      }
    }
  }
}
