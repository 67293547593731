@import '../../styles/tools';

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  @include desktop {
    margin-top: 0;
    height: calc(80vh - 150px);
  }
}
