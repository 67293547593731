@import './tools';

@font-face {
  font-family: 'BigCaslonRegular';
  src: local('BigCaslonRegular'), url(../fonts/BigCaslonRegular.otf) format('opentype');
}

html {
  box-sizing: border-box;
  font-size: 62.5%;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
}

body {
  font-family: 'BigCaslonRegular', Times, serif;
  background-color: white;
  color: #333;
  font-size: 2rem;
  overflow: hidden;
}

input,
textarea,
select,
button {
  color: inherit;
  font: inherit;
}

h1 {
  font-size: 2.2rem;

  @include desktop {
    font-size: 3rem;
  }
}

h2 {
  font-size: 2.8rem;
}

h3 {
  font-size: 2rem;
}

p {
  line-height: 1.2;
}

a {
  text-decoration: none;
}

a,
button,
input[type='submit'],
input[type='button'] {
  cursor: pointer;
  outline: none;

  &[disabled] {
    cursor: not-allowed;
  }
}

*:focus-visible {
  box-shadow: 0 0 3px 3px rgba(0, 103, 244, 0.247);
}

ul {
  list-style: none;
}

strong {
  font-weight: bold;
}

main {
  display: flex;
  justify-content: center;
  height: calc(100vh - var(--header-height) * 2);

  @include desktop {
    height: calc(100vh - var(--header-height));
  }
}
