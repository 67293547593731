@import '../../styles/tools';

.container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: absolute;
  top: var(--header-height);
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.9);
  padding: 0 space(2);

  @include landscape {
    top: calc(var(--header-height) - 10px);
    overflow-y: scroll;
  }

  .inner {
    ul {
      @include landscape {
        padding-bottom: calc(var(--header-height) * 1.5);
      }
      li {
        & + li {
          margin-top: space(4);
        }
      }
    }

    @include desktop {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 50px;
      height: calc(80vh - 150px);
    }

    @include landscape {
      display: block;
    }

    .contactInfo {
      margin-top: space(6);
    }
  }
}
