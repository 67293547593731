@import '../../styles/tools';

.img {
  width: 80vw;
  max-height: calc(60vh - 100px);
  object-fit: contain;
  object-position: top;

  @include desktop {
    max-height: 60vh;
  }

  @include landscape {
    margin-top: calc(var(--header-height) / 2);
  }
}
