@import '../../styles/tools';

.container {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 300vw;
  height: 300vw;
  transform: translate(-50%, -50%) rotate(45deg);

  button {
    width: 50%;
    height: 50%;
  }

  .navButton {
    border: none;
    background: none;
    opacity: 0.3;
    text-indent: -999999px;

    &.hidden {
      display: none;
    }

    &.up {
      cursor: url('/up.png'), auto;
    }

    &.down {
      cursor: url('/down.png'), auto;
    }

    &.left {
      cursor: url('/left.png'), auto;
    }

    &.right {
      cursor: url('/right.png'), auto;
    }
  }
}
